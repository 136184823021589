<template>
    <div>
        <Modal title="通知內容" v-model="isShowModal">
            <template v-slot:modal-title-icon>
                <div v-if="insServicesCount" class="has-event-icon">
                    {{ insServicesCount }}
                </div>
            </template>
            <template v-slot:modal-content>
                <InsuranceEventTable
                    v-if="isShowModal"
                    :dataList="dataList"
                    :isLoading="isLoading"
                    :isViewAll="true"
                    v-model:selected="selectedNotices"
                />
            </template>
            <template v-slot:modal-bottom>
                <Button
                    v-if="insServicesCount && $hasPermission('completeNotice')"
                    buttonWord="下次再處理"
                    buttonStyle="grey"
                    @click="closeModal"
                />
                <Button
                    v-if="insServicesCount && $hasPermission('completeNotice')"
                    buttonWord="完成"
                    buttonStyle="blue"
                    :disabled="!hasCheckedNotice"
                    @click="confirm"
                />
                <Button
                    v-if="
                        !insServicesCount || !$hasPermission('completeNotice')
                    "
                    buttonWord="關閉"
                    buttonStyle="blue"
                    @click="closeModal"
                />
            </template>
        </Modal>
        <CompleteNoticeModal
            :dataList="dataList"
            :insServicesCount="insServicesCount"
            v-model="isShowConfirmModal"
            v-model:selected="selectedNotices"
            @golaststep="isShowModal = true"
            @updateinsservices="updateInsServices"
        />
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import InsuranceEventTable from '@/containers/tia/InsuranceEventTable.vue'
import CompleteNoticeModal from '@/containers/tia/CompleteNoticeModal.vue'

export default {
    name: 'InsuranceDetailEventModal',
    emits: ['update:modelValue'],
    inheritAttrs: false,
    components: {
        Modal,
        Button,
        InsuranceEventTable,
        CompleteNoticeModal
    },
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        dataList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        insServicesCount: function () {
            return (
                this.dataList?.insServices?.filter(
                    (service) => service.isChecked === false
                )?.length || 0
            )
        },
        hasCheckedNotice: function () {
            return Object.values(this.selectedNotices).some(
                (checked) => checked
            )
        }
    },
    methods: {
        confirm: function () {
            this.isShowModal = false
            this.isShowConfirmModal = true
            this.$store.commit('set', {
                isShowModalForGa: true
            })
            this.$setGaEvent('showConfirm', 'click-Button', {
                params: 'detail',
                isModal: true
            })
        },
        closeModal: function () {
            this.$setGaEvent('closeNoticeModal', 'click-Button', {
                params: 'detail',
                isModal: true
            })
            this.isShowModal = false
        },
        updateInsServices: function (completed) {
            this.dataList?.insServices?.forEach((service) => {
                if (completed.indexOf(service.notifyId) >= 0) {
                    service.isChecked = true
                }
            })
        }
    },
    data() {
        return {
            isShowConfirmModal: false,
            selectedNotices: {},
            checkedList: []
        }
    }
}
</script>

<style lang="scss" scoped>
.has-event-icon {
    position: relative;
    display: inline-block;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    border-radius: 100%;
    background-color: $primary-red;
    color: $primary-white;
    font-weight: bold;
    transform: scale(0.9);
    font-size: 12px;
    line-height: 24px;
}

.modal {
    &:deep(.modal-size) {
        width: 780px;
        .event-table {
            table {
                min-width: 600px;
            }
        }
    }
}
.ins-content {
    :deep(.ins-content-container) {
        grid-template-columns: repeat(auto-fill, 210px);
        -ms-grid-columns: repeat(auto-fill, 210px);
    }
}
</style>
